import { ReactNode } from 'react'
import { LocationFilterElementType } from '../domain-config/domain-config'
import { ButtonIcon } from '@/components/common/button/constants'

export const CURRENCY_COOKIE = 'CURRENCY'

export interface BasePage {
  status: number
  url: string
  titleMetaTag: string
  descriptionMetaTag: string
  canonicalTag: string
  hreflangs: HrefLang[]
  jsonLd: string
}

export interface HrefLang {
  lang: string
  url: string
}

export interface Page extends StageWithSearchProps, BasePage {
  breadcrumb?: BreadcrumbItem[]
  content: PageContent[]
  pageId: string
  noIndex: boolean
}

export interface StageWithSearch {
  properties: StageWithSearchProps
  type: PageComponentTypes.HERO_WITH_SEARCH
}

export interface Stage {
  properties: StageProps
  type: PageComponentTypes.HERO
}

export interface StageProps {
  headline: string
  imageFileName: string
  imageAltTag: string
  imageTitleTag: string
}

export interface StageWithSearchProps extends StageProps {
  search: SearchProps
  trust?: TrustHeaderProperties
}

export interface BlogHeroProps {
  headline: string
  imageFileName: string
  imageAltTag: string
  imageTitleTag: string
}

export interface BlogReadingTimeProps {
  time: string
}

export interface BlogPublicationDateProps {
  date: string
}

export interface BlogListProps {
  ListItem: string[]
  headline: string
  subheadline: string
}

export interface BlogAutorProps {
  name: string
  imageFileName: string
  imageAltTag: string
  description: string
  link: string
}

export interface BlogArticleTeaserProps {
  items: BlogArticleTeaser[]
}

export interface BlogArticleTeaser {
  headline: string
  imageFileName: string
  text: string
  url: string
  subtitle: string | null
}

export interface SearchProps {
  destinations?: SearchItem[]
  topics?: SearchItem[]
}

export interface SearchItem {
  type: SearchResultItemCategory | LocationFilterElementType
  value: string
  label: string
  text: string
}

export enum SearchResultItemCategory {
  CountryGroup = 'cg',
  Country = 'c',
  Region = 'r',
  Hotel = 'h',
  Topic = 't',
  TopicType = 'tt',
  Location = 'l',
  MapArea = 'ma',
}

export enum Currency {
  EUR = 'EUR',
  CHF = 'CHF',
  USD = 'USD',
  PLN = 'PLN',
  GBP = 'GBP',
  AUD = 'AUD',
  SEK = 'SEK',
}

export const CURRENCIES: Currency[] = Object.values(Currency)

export type PageContent =
  | Headline
  | TrustHeader
  | Trust
  | Text
  | Table
  | Button
  | TextWithButton
  | Link
  | TableOfContents
  | Hint
  | Faq
  | ImageGrid
  | Header
  | HeaderWithImage
  | Hotels
  | ImagesWithText
  | BlogTeaserRow
  | ImageRow
  | StageWithSearch
  | Stage
  | Contact
  | Incentives
  | StudySearchHeader

export interface Properties {
  text?: string
  imageFileName?: string
  imageAltTag?: string
  imageTitleTag?: string
  imageCaption?: string
  imageWidth?: string
  imageRatio?: string
}

export interface IncentivesItem {
  svgIcon: string
  headline: string
  text: string
}

export interface IncentivesProperties {
  headline: string
  incentiveItems: IncentivesItem[]
  text: string
}

export interface Incentives {
  properties: IncentivesProperties
  type: PageComponentTypes.INCENTIVES
}

export interface TrustHeader {
  properties: TrustHeaderProperties
  type: PageComponentTypes.TRUST_HEADER
}

export interface Trust {
  properties: TrustProps
  type: PageComponentTypes.TRUST
}

export interface TrustProps {
  items: TrustItem[]
}

export interface TrustItem {
  value: number
  type: string
}

export enum TrustHeaderIcons {
  THUMB = 'thumb',
  MONEY = 'money',
  COLLEGE = 'college',
  SPEECH_BUBBLE = 'speech-bubble',
}

export interface TrustHeaderProperties {
  icons: TrustHeaderIcons[]
  texts: string[]
  images?: string[]
}

export interface Headline {
  properties: HeadLineProperties
  type: PageComponentTypes.HEADLINE
}

export interface HeadLineProperties {
  text: string
  level: HeadlineLevel
  tableOfContentsId?: string
}

export interface Text {
  properties: TextProperties
  type: PageComponentTypes.TEXT
}

export interface TextProperties {
  text: string
}
export interface Hint {
  properties: TextProperties
  type: PageComponentTypes.HINT
}

export interface HintProperties {
  text: string
}

export interface Button {
  properties: ButtonProperties
  type: PageComponentTypes.BUTTON
}

export enum LinkTarget {
  BLANK = '_blank',
  MODAL = 'modal',
  SELF = '_self',
  POST_REDIRECT_GET = 'PostRedirectGet',
}

export interface ButtonProperties {
  linkUrl: string
  linkText: string
  linkTarget: LinkTarget
  linkPostRequest: boolean
}

export interface Contact {
  properties: ContactProps
  type: PageComponentTypes.CONTACT
}

export interface ContactProps {
  headline1: string | undefined
  headline2: string | undefined
  name1: string | undefined
  name2: string | undefined
  phoneDisplay: string | undefined
  phoneE164: string | undefined
  buttontext: string | undefined
  buttonlink: string | undefined
  imageFileName: string | undefined
}

export interface TextWithButton {
  properties: TextWithButtonProperties
  type: PageComponentTypes.TEXT_WITH_BUTTON
}

export interface TextWithButtonProperties {
  linkUrl: string
  linkText: string
  linkTarget: LinkTarget
  text: string
  icon?: ButtonIcon
  linkPostRequest: boolean
}

export interface Header {
  properties: HeaderProperties
  type: PageComponentTypes.HEADER
}

export interface HeaderProperties {
  subTitle: string
  title: string
  text: string
}

export interface HeaderWithImage {
  properties: HeaderWithImageProperties
  type: PageComponentTypes.HEADER_WITH_IMAGE
}

export interface HeaderWithImageProperties {
  subTitle: string
  title: string
  text: string
  imageFileName: string
  imageAltTag: string
  imageTitleTag: string
}

export interface Table {
  properties: TableProperties
  type: PageComponentTypes.TABLE
}

export interface TableProperties {
  headlineText: string
  headlineLevel: HeadlineLevel
  content: string
}

export interface TableItem {
  headlineText?: string
  headlineLevel?: string
}

export interface Link {
  properties: LinkProperties
  type: PageComponentTypes.LINKS
}

export interface LinkProperties {
  items: LinkItem[]
}

export interface LinkItem {
  text: string
  url: string
  linkTarget: LinkTarget
}

export interface TableOfContents {
  properties: TableOfContentsProperties
  type: PageComponentTypes.TABLE_OF_CONTENTS
}

export interface TableOfContentsProperties {
  tableOfContentsItems: TableOfContentsItem[]
}

export interface TableOfContentsItem {
  text: string
  url: string
}

export interface LogoCloud {
  properties: LogoCloudProperties
  type: PageComponentTypes.LOGO_CLOUD
}

export interface LogoCloudProperties {
  headline: string
  logos: string
}

export interface BreadcrumbItem {
  name: string
  url: string
}

export interface Faq {
  properties: FaqProperties
  type: PageComponentTypes.FAQ
}

export interface FaqProperties {
  faqItems: FaqItem[]
}

export interface FaqItem {
  question: string
  answer: string
}

export interface ImageGrid {
  properties: ImageGridProperties
  type: PageComponentTypes.IMAGE_GRID
}

export interface ImageGridProperties {
  items: ImageGridItem[]
}

export interface ImageGridItem {
  url: string
  text: string
  image: string
  minPrice?: string
}

export interface ImagesWithText {
  properties: ImageWithTextProperties
  type: PageComponentTypes.IMAGES_WITH_TEXT
}

export interface ImageWithTextProperties {
  imagesWithTextItems: ImagesWithTextItem[]
}

export interface ImagesWithTextItem {
  linkUrl: string
  linkTarget: ImagesWithTextItemLinkTarget | undefined
  linkNoFollow: boolean
  imageTitleTag: string
  imageCaption: string
  imageAltTag: string
  imageFileName: string
  minPrice: string
  text: string
}

export enum ImagesWithTextItemLinkTarget {
  MODAL = 'Modal',
  POST_REDIRECT_GET = 'PostRedirectGet',
  BLANK = 'Blank',
}

export interface StudyCategoryHeader {
  properties: StudyCategoryHeaderProperties
  types: PageComponentTypes.STUDY_CATEGORY_HEADER
}

export interface StudyCategoryHeaderProperties {
  imageFileName: string
  headline: string
  subtitle: string
  text: string
  imageCaption: string
  imageAltTag: string
  imageTitleTag: string
}

export interface BlogHeroElementProperties {
  imageFileName: string
  headline: string
  subtitle: string
  text: string
  imageCaption: string
  imageAltTag: string
  imageTitleTag: string
}

export interface StudySearchHeader {
  properties: StudySearchHeaderProperties
  types: PageComponentTypes.STUDY_SEARCH_HEADER
  type: string
}

export interface StudySearchHeaderProperties {
  headline: string
  subtitle: string
  imageFileName: string
  imageCaption: string
  imageAltTag: string
  imageTitleTag: string
  type: string
}

export interface StudyCategoryCards {
  properties: StudyCategoryCardsProperties
  types: PageComponentTypes.STUDY_CATEGORY_CARDS
}

export interface StudyCategoryCardsProperties {
  items: StudyCategoryCard[]
}

export interface StudyCategoryCard {
  headline: string
  imageFileName: string
  url: string
  quantity: number
  links: StudyCategoryCardLinks[]
}

export interface StudyCategoryCardLinks {
  title: string
  url: string
}

export interface StudyArticleCards {
  properties: StudyArticleCardsProperties
  types: PageComponentTypes.STUDY_ARTICLE_CARDS
}

export interface StudyArticleCardsProperties {
  items: StudyArticleCard[]
}

export interface StudyArticleCard {
  title: string
  url: string
  editor: string
  releaseDate: string
  shortDescription: string
  topics: string[]
}

export interface StudyArticle {
  properties: StudyArticleProperties
  types: PageComponentTypes.STUDY_ARTICLE
}

export interface StudyArticleProperties {
  linkToStudyText: ReactNode
  subline: string
  title: string
  url: string
  Indications: string
  Institutions: string
  description: string
  imageFileName: string
  imageAltTag: string
  imageTitleTag: string
  editor: string
  releaseDate: string
  subjects: string
  tags: StudyArticleSummaryTags[]
  text: string
  subtitle: string
  indications: string
  institutions: string
  studyTitle: string
  shortDescription: string
  originalTitle: string
  link: string
  topics: string[]
}

export interface StudyArticleSummaryTags {
  title: string
}

export interface BlogTeaserRow {
  properties: BlogTeaserRowProperties
  type: PageComponentTypes.BLOG_TEASER_ROW
}

export interface BlogTeaserItems {
  blogPostName: string
  blogPostDate: string
  imageFileName: string
  imageAltTag: string
  imageTitleTag: string
  url: string
  blogCode: string
  author: string
}

export interface BlogTeaserRowProperties {
  items: BlogTeaserItems[]
}

export interface ImageRow {
  properties: ImageRowProperties
  type: PageComponentTypes.IMAGE_ROW
}

export interface ImageRowProperties {
  items: ImageRowItem[]
}

export interface ImageRowItem {
  url: string
  text: string
  image: string
  minPrice: string
}

export interface Hotels {
  properties: HotelsProperties
  type: PageComponentTypes.HOTELS
}

export interface HotelItem {
  topBar: string | undefined
  hotelName: string
  hotelCategoryText: string
  programName: string
  images: string[]
  annotation: string
  ratingText: string
  priceLine2?: string
  priceLine1?: string
  discount?: string
  url: string
  hotelCode: string
  destinations?: string[]
  specialCategory?: string
  ratingValue?: number
  hotelCategoryStars?: number
  attribute?: string
  isKmwHotel: boolean
}

export interface HotelsProperties {
  items: HotelItem[]
  noWrapper: boolean
}

export interface TextWithImage {
  properties: TextWithImageProperties
  type: PageComponentTypes.TEXT_WITH_IMAGE
}

export enum TextWithImageImagePosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface TextWithImageProperties {
  text: string
  imagePosition: TextWithImageImagePosition
  imageFileName: string
  imageAltTag: string
  imageTitleTag: string
  imageCaption?: string
  imageRatio: ImageRatio
}

export interface ImageProperties {
  imageFileName: string
  imageAltTag: string
  imageTitleTag: string
  imageCaption?: string
  imageWidth: ImageWidth
  imageRatio: ImageRatio
}

export interface DynamicWidthImageProperties extends ImageProperties {
  imageRatioWidth: string
  imageRatioHeigth: string
}

export interface WizardProps {
  imageFileName: string
  imageAltTag: string
  imageTitleTag: string
}

export enum ImageWidth {
  WIDTH_50 = 'width50',
  WIDTH_75 = 'width75',
  WIDTH_100 = 'width100',
}

export enum ImageRatio {
  RATIO_16_9 = 'ratio_16_9',
  RATIO_4_3 = 'ratio_4_3',
  RATIO_3_4 = 'ratio_3_4',
  RATIO_1_1 = 'ratio_1_1',
  Original = 'original',
}

export enum PageComponentTypes {
  HEADLINE = 'headline',
  TRUST_HEADER = 'trustheader',
  TRUST = 'trust',
  TEXT = 'text',
  TABLE = 'table',
  BUTTON = 'button',
  LINKS = 'links',
  FAQ = 'faq',
  IMAGE_GRID = 'imagegrid',
  HEADER = 'header',
  HINT = 'hint',
  TABLE_OF_CONTENTS = 'tableofcontents',
  HEADER_WITH_IMAGE = 'headerwithimage',
  HOTELS = 'hotelteaserrow',
  TEXT_WITH_BUTTON = 'textwithbutton',
  TEXT_WITH_IMAGE = 'textwithimage',
  IMAGES_WITH_TEXT = 'imageswithtext',
  BLOG_TEASER_ROW = 'blogteaserrow',
  IMAGE_ROW = 'imagerow',
  HERO_WITH_SEARCH = 'herowithsearch',
  HERO = 'hero',
  IMAGE = 'image',
  CONTACT = 'contactbox',
  INCENTIVES = 'incentives',
  LOGO_CLOUD = 'logocloud',
  STUDY_HEADER = 'studyheader',
  STUDY_SEARCH_HEADER = 'studysearchheader',
  STUDY_CATEGORY_CARDS = 'studycategorycards',
  STUDY_ARTICLE_CARDS = 'studyarticlecards',
  STUDY_ARTICLE = 'studyarticle',
  STUDY_CATEGORY_HEADER = 'studycategoryheader',
  BLOG_HERO = 'bloghero',
  BLOG_READING_TIME = 'blogreadingtime',
  BLOG_PUBLICATION_DATE = 'blogpublicationdate',
  BLOG_ARTICLE_TEASER = 'blogarticleteaser',
  BLOG_AUTHOR = 'blogauthor',
  BLOG_LIST = 'bloglist',
}

export enum HeadlineLevel {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
}

export interface GroupedDestinationSearchResult {
  [SearchResultItemCategory.Hotel]: SearchItem[]
  [SearchResultItemCategory.Region]: SearchItem[]
  [SearchResultItemCategory.Country]: SearchItem[]
  [SearchResultItemCategory.CountryGroup]: SearchItem[]
}

export enum Language {
  DE = 'de',
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  IT = 'it',
  NL = 'nl',
  PL = 'pl',
  SV = 'sv',
}
