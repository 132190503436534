'use client'

import React, { ReactElement, ReactNode } from 'react'
import { Domain, ExtendedDomainConfiguration } from '../../domain-config/domain-config'
import { ASSET_SERVER_URL } from '@/lib/sharedConstants'

export const ConfigContext = React.createContext<
  | (ExtendedDomainConfiguration & {
  skipHeaderAndFooter: boolean
  eagerLoadImages: boolean
  domain: Domain
  assetServerUrl: string
})
  | undefined
>(undefined)

interface ConfigContextProps {
  skipHeaderAndFooter?: boolean
  domain: Domain
  children: ReactNode
  config: ExtendedDomainConfiguration
  eagerLoadImages?: boolean
}

export default function ConfigContextProvider(props: ConfigContextProps): ReactElement {
  const extendedConfig = {
    ...props.config,
    domain: props.domain,
    eagerLoadImages: !!props.eagerLoadImages,
    skipHeaderAndFooter: !!props.skipHeaderAndFooter,
    assetServerUrl: ASSET_SERVER_URL,
  }

  return <ConfigContext.Provider value={extendedConfig}>{props.children}</ConfigContext.Provider>
}
