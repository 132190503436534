import { Domain } from '../domain-config/domain-config'

export const GTAG_ID = 'GTM-M9G7X6'
export const PROXY_SUBDOMAIN = process.env.PROXY_SUBDOMAIN || 'https://website.prod.'
export const WEBSITE_CREDENTIALS_USER = process.env.WEBSITE_CREDENTIALS_USER || 'production'
export const WEBSITE_CREDENTIALS_PW = process.env.WEBSITE_CREDENTIALS_PW || 'jodelet6gusuliau'
export const ASSET_SERVER_URL: string =
  process.env.ASSET_SERVER_URL || 'https://img.fitreisen.group'

export const SKIP_CACHE_WARMING: boolean = process.env.SKIP_CACHE_WARMING === 'true'
export const SKIP_AUTHENTIFICATION: boolean = process.env.SKIP_AUTHENTIFICATION === 'true'
export const FALLBACK_DOMAIN: Domain =
  (process.env.FALLBACK_DOMAIN as Domain) || Domain.FITREISEN_DE
export const CURRENCY_SEARCH_PARAMETER = 'currency'
export const COOKIE_LIFETIME_IN_DAYS = 60

export const HEADER_NAME_FEATURE_FLAGS_PATH = 'X-FIT-FEATURE-FLAGS-PATH'
export const PUBLIC_MAPBOX_TOKEN =
  'pk.eyJ1IjoiZml0cmVpc2VuIiwiYSI6ImNqdHppeXlkMDBiazk0M3QxZDFpNGNqYmgifQ.f6VsL4UgB13NPCXv9-vJGQ'

export enum FilterModalType {
  ALL,
  PRICE,
  GUESTS,
  RATING,
  LOCATION,
  ORDER,
  ORDER_MOBILE,
  STARS,
}

export enum SearchSection {
  TopCountriesGroup = 'topCountrygroupLabel',
  CountryGroup = 'countrygroupLabel',
  TopCountries = 'topCountriesLabel',
  Country = 'countryLabel',
  TopRegions = 'topRegionsLabel',
  Region = 'regionLabel',
  TopHotels = 'topHotelsLabel',
  Hotel = 'hotelLabel',
  TopLocations = 'topLocationsLabel',
  Location = 'locationLabel',
  Topic = 'topicsLabel',
  TopTopics = 'topTopicsLabel',
}

export const WIZARD_INPUT_KEY_PRESSED = 'WIZARD_INPUT_KEY_PRESSED'
export const WIZARD_INPUT_BLUR = 'WIZARD_INPUT_BLUR'
export const MAP_DISPLAY_STATUS_CHANGED = 'MAP_DISPLAY_STATUS_CHANGED'
export const CLOSE_MAP_CLICK = 'CLOSE_MAP_CLICK'

export enum PageType {
  SEO = 'seo',
  SEM = 'sem',
}

export const PAGE_TYPES = Object.values(PageType)

export const wizardLabels = {
  destinationInputProps: {
    mobileTitle: 'destinationLabelMobile',
    label: 'destinationLabel',
    placeholderDesktop: 'destinationPlaceholderDesktop',
    placeholderMobile: 'destinationLabel',
    placeholderSearchPage: 'placeHolderSearchPageDestination',
  },
  topicInputProps: {
    mobileTitle: 'topicLabelMobile',
    label: 'topicLabel',
    placeholderMobile: 'topicLabel',
    placeholderDesktop: 'topicLabelMobile',
    placeholderSearchPage: 'placeHolderSearchPageTopic',
  },
  travelPeriodInputProps: {
    mobileTitle: 'travelPeriodPlaceholder',
    label: 'travelPeriodLabel',
    placeholderDesktop: 'travelPeriodPlaceholder',
    placeholderMobile: 'travelPeriodPlaceholder',
    placeholderSearchPage: 'placeHolderSearchPageTravelPeriod',
  },
}
export const API_URL = process.env.API_URL || 'https://api.fitreisen.de/v3/'

export enum Locale {
  DE = 'de',
  EN = 'en',
  FR = 'fr',
  ES = 'es',
  IT = 'it',
  PL = 'pl',
  SV = 'sv',
  NL = 'nl',
}

export enum Country {
  DE = 'DE',
  ES = 'ES',
  FR = 'FR',
  IT = 'IT',
  PL = 'PL',
  SE = 'SE',
  NL = 'NL',
  GB = 'GB',
}

export const LOCALES = Object.values(Locale)
