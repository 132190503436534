import { HTMLAttributeAnchorTarget } from 'react'
import { Currency, Language, SearchItem } from '@/types/Page'
import { Country, Locale } from '@/lib/sharedConstants'

type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object ? Subset<K[attr]> : K[attr]
}

export enum Domain {
  FITREISEN_DE = 'fitreisen.de',
  WELLNESSURLAUB_COM = 'wellnessurlaub.com',
  YOGAESCAPES_DE = 'yogaescapes.de',
  AYTOUR_DE = 'aytour.de',
  SPADREAMS_COM = 'spadreams.com',
  SPADREAMS_ES = 'spadreams.es',
  SPADREAMS_FR = 'spadreams.fr',
  SPADREAMS_IT = 'spadreams.it',
  SPADREAMS_NL = 'spadreams.nl',
  SPADREAMS_PL = 'spadreams.pl',
  SPADREAMS_SE = 'spadreams.se',
  LOTUS_TRAVEL_COM = 'lotus-travel.com',
}

export const DOMAINS = Object.values(Domain)

export enum SocialMediaPlatform {
  FACEBOOK,
  INSTAGRAM,
  PINTEREST,
  YOUTUBE,
  LINKEDIN,
}

interface SocialMediaLink {
  platform: SocialMediaPlatform
  url: string
}

//Only two elements because the layout is not robust adding more elements
type Footer = { links: LinkConfigItem[]; socialMediaLinks: SocialMediaLink[] }

type Price = { [key in Currency]: string[] }

export interface LocationFilterElement {
  value: string
  type: LocationFilterElementType
  label?: string
  separated?: boolean
}

export enum LocationFilterElementType {
  Property = 'prop',
  RoomType = 'rt',
  MealPlan = 'mp',
  Allotment = 'allotment',
  Stars = 'smin',
}

class ConfigurationInstance {
  establishedSince: number
  companyName: string
  companyNameJsonLd: string
  renderJsonLdOrganization: boolean
  gaMeasurementId: string
  footer: Footer
  header: HeaderConfig
  recommendedTravelTopics: SearchItem[]
  recommendedDestinations: SearchItem[]
  language: Language
  locale: Locale
  country: Country
  currency: Currency
  defaultAgencyNumber: number
  contactEmail: string
  searchFilters: {
    price: Price
    location: LocationFilterElement[]
    additionalFilters: Record<string, LocationFilterElement[]>
  }
  primaryColor: string
  sitemaps: string[]
  faqPageUrl: string | null
  legalPages: {
    privacyPolicy: string
    termsAndConditions: string
    travelInformation: string
    entryAndHealthRegulations: string
  }
}

export type Configuration = ConfigurationInstance
export type PartialConfiguration = Subset<Configuration>

export interface ExtendedConfiguration extends Subset<Configuration> {
  extends: PartialConfiguration[]
}

export interface ExtendedDomainConfiguration extends Configuration {
  domain: Domain
}

export interface LinkConfigItem {
  label: string
  url: string
  target?: HTMLAttributeAnchorTarget
}

export interface HeaderConfig {
  topLevelItems: LinkConfigItem[]
  contactPhone: {
    label: string
    phone: string
    showByDefault: boolean
    showAlways: boolean
  }
  contact: LinkConfigItem
  service: LinkConfigItem
  bookmarks: LinkConfigItem
  onMobileShowContactAndHidePhone: boolean
}

export const BASE_SEARCH_FILTER_CONFIG: PartialConfiguration = {
  searchFilters: {
    price: {
      [Currency.AUD]: [
        '$250',
        '$500',
        '$750',
        '$1000',
        '$1500',
        '$2000',
        '$3000',
        '$4000',
        '$5000',
        '$6000',
        '$7000',
        '$10000',
        '$15000',
      ],
      [Currency.CHF]: [
        'Fr. 250',
        'Fr. 500',
        'Fr. 750',
        "Fr. 1'000",
        "Fr. 1'500",
        "Fr. 2'000",
        "Fr. 3'000",
        "Fr. 4'000",
        "Fr. 5'000",
        "Fr. 6'000",
        "Fr. 7'000",
        "Fr. 10'000",
        "Fr. 15'000",
      ],
      [Currency.EUR]: [
        '150 €',
        '300 €',
        '500 €',
        '750 €',
        '1.000 €',
        '1.500 €',
        '2.000 €',
        '2.500 €',
        '3.000 €',
        '4.000 €',
        '5.000 €',
        '7.000 €',
        '10.000 €',
      ],
      [Currency.GBP]: [
        '£100',
        '£200',
        '£400',
        '£600',
        '£800',
        '£1,000',
        '£1,500',
        '£2,000',
        '£2,500',
        '£3,000',
        '£4,000',
        '£5,000',
        '£7,000',
      ],
      [Currency.PLN]: [
        '500 zł',
        '1 000 zł',
        '2 000 zł',
        '3 000 zł',
        '4 000 zł',
        '6 000 zł',
        '7 000 zł',
        '10 000 zł',
        '12 000 zł',
        '15 000 zł',
        '20 000 zł',
        '25 000 zł',
        '30 000 zł',
      ],
      [Currency.SEK]: [
        '1 000 kr',
        '2 500 kr',
        '5 000 kr',
        '7 500 kr',
        '10 000 kr',
        '15 000 kr',
        '20 000 kr',
        '25 000 kr',
        '30 000 kr',
        '40 000 kr',
        '50 000 kr',
        '70 000 kr',
        '100 000 kr',
      ],
      [Currency.USD]: [
        '$150',
        '$300',
        '$500',
        '$750',
        '$1,000',
        '$1,500',
        '$2,000',
        '$2,500',
        '$3,000',
        '$4,000',
        '$5,000',
        '$7,000',
        '$10,000',
      ],
    },
  },
}

export const BASE_CONFIG: PartialConfiguration = {
  searchFilters: {
    location: [
      {
        value: '1899',
        type: LocationFilterElementType.Property,
      },
      {
        value: '1900',
        type: LocationFilterElementType.Property,
      },
      {
        value: '1952',
        type: LocationFilterElementType.Property,
      },
      {
        value: '1903',
        type: LocationFilterElementType.Property,
      },
      {
        value: '1902',
        type: LocationFilterElementType.Property,
      },
      {
        value: '1901',
        type: LocationFilterElementType.Property,
      },
      {
        value: '1904',
        type: LocationFilterElementType.Property,
      },
      {
        value: '2343',
        type: LocationFilterElementType.Property,
      },
    ],
    additionalFilters: {
      roomType: [
        {
          label: 'apartment',
          value: '3',
          type: LocationFilterElementType.RoomType,
        },
        {
          label: 'threeBedRoom',
          value: '5',
          type: LocationFilterElementType.RoomType,
        },
        {
          label: 'doubleRoom',
          value: '2',
          type: LocationFilterElementType.RoomType,
        },
        {
          label: 'singleRoom',
          value: '1',
          type: LocationFilterElementType.RoomType,
        },
        {
          label: 'suite',
          value: '4',
          type: LocationFilterElementType.RoomType,
        },
        {
          label: 'familyRoom',
          value: '7',
          type: LocationFilterElementType.RoomType,
        },
      ],
      boardType: [
        {
          label: 'allInclusive',
          value: '5',
          type: LocationFilterElementType.MealPlan,
        },
        {
          label: 'breakfast',
          value: '2',
          type: LocationFilterElementType.MealPlan,
        },
        {
          label: 'fullBoard',
          value: '4',
          type: LocationFilterElementType.MealPlan,
        },
        {
          label: 'halfBoard',
          value: '3',
          type: LocationFilterElementType.MealPlan,
        },
        {
          label: 'withoutBoard',
          value: '1',
          type: LocationFilterElementType.MealPlan,
        },
        {
          label: 'specialMeals',
          value: '6',
          type: LocationFilterElementType.MealPlan,
        },
        {
          value: '2128',
          type: LocationFilterElementType.Property,
          separated: true,
        },
        {
          value: '2106',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2300',
          type: LocationFilterElementType.Property,
        },
        {
          value: '1914',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2008',
          type: LocationFilterElementType.Property,
        },
        {
          value: '1916',
          type: LocationFilterElementType.Property,
        },
        {
          value: '1915',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2207',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2007',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2006',
          type: LocationFilterElementType.Property,
        },
        {
          value: '1951',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2134',
          type: LocationFilterElementType.Property,
        },
        {
          value: '1917',
          type: LocationFilterElementType.Property,
        },
        {
          value: '1913',
          type: LocationFilterElementType.Property,
        },
      ],
      amenities: [
        {
          value: '2206',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2342',
          type: LocationFilterElementType.Property,
        },
        {
          value: '1890',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2002',
          type: LocationFilterElementType.Property,
        },
        {
          value: '1905',
          type: LocationFilterElementType.Property,
        },
        {
          value: '1885',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2041',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2091',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2412',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2302',
          type: LocationFilterElementType.Property,
        },
        {
          value: '1891',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2331',
          type: LocationFilterElementType.Property,
        },
        {
          value: '1949',
          type: LocationFilterElementType.Property,
        },
        {
          value: '1986',
          type: LocationFilterElementType.Property,
        },
        {
          value: '1896',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2092',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2353',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2338',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2139',
          type: LocationFilterElementType.Property,
        },
        {
          value: '1894',
          type: LocationFilterElementType.Property,
        },
        {
          value: '1889',
          type: LocationFilterElementType.Property,
        },
      ],
      sport: [
        {
          value: '2015',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2010',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2017',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2307',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2013',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2012',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2009',
          type: LocationFilterElementType.Property,
        },
        {
          value: '1897',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2020',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2011',
          type: LocationFilterElementType.Property,
        },
        {
          value: '2016',
          type: LocationFilterElementType.Property,
        },
      ],
      allotment: [
        {
          value: '1',
          type: LocationFilterElementType.Allotment,
        },
      ],
    },
  },
}
